import * as React from 'react'
import * as cn from 'classnames'
import { BlogRoll } from 'tuna/components/blog-roll'
import { Page } from 'tuna/components/page'
import componentStyles from 'tuna/styles/pages.module.scss'

export default () => (
  <Page className={cn(componentStyles.homePage)}>
    <h1>Tunatune</h1>
    <BlogRoll />
  </Page>
)
