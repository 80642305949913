import * as React from "react"
import { Link } from 'gatsby'

const BLOG_ROOT = '/blog'

interface BlogRollPresentationProps {
  posts: any[]
}

export const BlogRollPresentation: React.FC<BlogRollPresentationProps> = props => {
  const { posts } = props
  return (
    <ul>
      {posts.map(post => (
        <li key={post.node.id}>
          <Link to={`${BLOG_ROOT}/${post.node.frontmatter.url}`}>
            {post.node.frontmatter.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}
