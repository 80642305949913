import * as React from "react"
import { graphql, StaticQuery } from 'gatsby'
import { BlogRollPresentation } from './component'

interface GQLQueryData {
  posts: {
    edges: any[]
  }
}

const BlogRollQuery = graphql`
  query BlogRollQuery {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { collection: { eq: "blog" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            url
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`

interface BlogRollProps {
  limit?: number
}

export const BlogRoll: React.FC<BlogRollProps> = () => (
  <StaticQuery
    query={BlogRollQuery}
    render={(data: GQLQueryData) => <BlogRollPresentation posts={data.posts.edges} />}
  />
)